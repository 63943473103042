
























































import { mapActions, mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { CompanyInfo } from '@/api/schema';
import formatDate from '@/helpers/formatDate';
import { DateTime } from 'luxon';
import VExtendedDataPicker from '@/components/VExtendedDataPicker.vue';

export default {
  name: 'SettingsCompanyInfo',
  components: {
    VExtendedDataPicker
  },
  data(): any {
    return {
      formData: {},
      formIsValid: false,
      modal: null
    };
  },
  computed: {
    ...mapState('Operator', ['loading', 'companyInfo']),
    rules(): any {
      return {
        text: [vr.required, (v) => vr.minLength(v, 3)],
        agreementNumber: [(v) => vr.minLength(v, 3)]
      };
    },
    disabled(): boolean {
      return this.$role.is(['super-admin-finance']);
    }
  },
  created(): void {
    this.getCompanyInfo();
  },
  watch: {
    companyInfo: {
      handler(newVal: CompanyInfo): void {
        const agreementDate = newVal.agreementDate
          ? formatDate(newVal.agreementDate, 'yyyy-LL-dd')
          : null;

        this.formData = {
          ...newVal,
          agreementDate
        };

        this.$refs.form?.resetValidation();
      },
      deep: true
    },
    operator: {
      handler(): void {
        this.getCompanyInfo();
      },
      deep: true
    }
  },
  methods: {
    ...mapActions('Operator', ['getCompanyInfo', 'saveCompanyInfo']),
    async submitHandler(): Promise<void> {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick(async (): Promise<void> => {
        if (this.formIsValid) {
          const fd = this.formData;
          await this.saveCompanyInfo({
            ...this.companyInfo,
            ...fd,
            agreementDate: fd.agreementDate
              ? DateTime.fromFormat(fd.agreementDate, 'yyyy-LL-dd').toFormat(
                  'yyyy-LL-dd HH:mm:ss'
                )
              : null
          } as CompanyInfo);

          this.$toast.success('Company info successfully updated!');
        }
      });
    }
  }
};
