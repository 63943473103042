

































































import { mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import { getAccountByID } from '@/api/SuperAdmin';
import SettingsRolesSelect from '@/views/SettingsViews/SettingsRolesSelect.vue';
import type {
  AccountUser,
  SettingsRoleDialogData,
  AccountItem,
  OperatorUserCreatePayload
} from '@/api/schema';
import type { PropType } from 'vue';
import { groupOperatorsByBrandName } from '@/helpers/operatorHelpers';

export default {
  name: 'SettingsRolesDialog',
  data(): any {
    return {
      formIsValid: false,
      itemData: {},
      showPassword: false,
      accountOperators: []
    };
  },
  components: {
    SettingsRolesSelect
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    item: {
      type: Object as PropType<AccountUser>,
      default: (): AccountUser => ({} as AccountUser)
    },
    operation: {
      type: String as PropType<string>,
      default: (): string => ''
    },
    dialogData: {
      type: Object as PropType<SettingsRoleDialogData>,
      default: (): SettingsRoleDialogData => {
        return {} as SettingsRoleDialogData;
      }
    }
  },
  computed: {
    ...mapState('Onboarding', ['operators']),
    ...mapState('app', ['isSuperAdmin']),
    disableSubmit(): boolean {
      if (Object.keys(this.itemData).length < 4) return true;
      return this.formIsValid && false;
    },
    header(): string {
      return this.dialogData?.header || '';
    },
    buttonText(): string {
      return this.dialogData?.buttonText || '';
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    rules(): any {
      return {
        password: [vr.required, (v) => vr.minLength(v, 6)],
        operatorUserId: [vr.required, (v) => vr.minLength(v, 6)],
        role: [vr.required],
        operators: [
          vr.required,
          (v) =>
            (v && v.length >= 1) ||
            'At least one operator should be selected.'
        ]
      };
    },
    submitData(): OperatorUserCreatePayload {
      return {
        ...(this.isSuperAdmin ? { accountId: this.$route.params.id } : {}),
        ...this.itemData
      };
    },
    gropedOperatorsList(): any {
      return Object.values(
        groupOperatorsByBrandName(this.accountOperators, true)
      )
        .flat(2)
        .splice(1);
    }
  },
  watch: {
    item(newValue: AccountUser): void {
      if (newValue) {
        this.$refs?.form?.reset?.();
        this.itemData = { ...newValue };

        if (this.isSuperAdmin) {
          getAccountByID(this.$route.params.id).then(
            (data: AccountItem): void => {
              this.accountOperators = [...data.operators];
            }
          );
          return;
        }

        this.accountOperators = this.operators;
      }
    }
  },
  methods: {
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (this.formIsValid) {
          this.$emit('submit', this.submitData);
        }
      });
    }
  }
};
