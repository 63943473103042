




























import BaseDialog from '@/components/BaseDialog.vue';
import { merge } from 'lodash';

interface IConfirmationDialogData {
  open: boolean;
  confirmationData: IConfirmationData;
  resolve: null | void;
}

interface IConfirmationData {
  title: string;
  text: string;
  warning: string | null;
  controls: {
    cancel: IConfirmationDataControl;
    submit: IConfirmationDataControl;
  };
}

interface IConfirmationDataControl {
  color: string;
  text: string;
}

const defaultConfirmationData = {
  title: 'Confirmation',
  text: 'Text confirmation',
  warning: null,
  controls: {
    cancel: {
      color: 'secondary',
      text: 'Cancel'
    },
    submit: {
      color: 'primary',
      text: 'Submit'
    }
  }
};
export default {
  name: 'ConfirmationPromiseDialog',
  components: {
    BaseDialog
  },

  props: {
    width: {
      type: [String, Number],
      default: 600
    }
  },

  data(): IConfirmationDialogData {
    return {
      open: false,
      confirmationData: { ...defaultConfirmationData },
      resolve: null
    };
  },

  methods: {
    openDialog(payload: IConfirmationData): Promise<boolean> {
      this.open = true;
      this.confirmationData = merge(this.confirmationData, payload);

      return new Promise((resolve) => {
        this.resolve = resolve;
      });
    },

    submit(): void {
      this.resolve(true);
      this.open = false;
    },

    cancel(): void {
      this.resolve(false);
      this.open = false;
    }
  }
};
