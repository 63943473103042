



































import { textOverflowMiddle } from '@/helpers/formatString';

export default {
  name: 'UserIdInput',
  props: {
    value: {
      type: Array,
      default: (): string[] => []
    },
    label: {
      type: String,
      default: 'Paste Operator User ID'
    },
    hint: {
      type: String
    },
    preventSpaceHandle: {
      type: Boolean,
      default: false
    },
    minSymbolsCount: {
      type: Number,
      default: 3
    },
    error: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      inputValue: null
    };
  },
  computed: {
    userList: {
      get(): string[] {
        return this.value || [];
      },
      set(val: string[]): void {
        this.updateValue(val);
      }
    },
    hasErrors(): string {
      return this.value?.some((id) => !this.validateUser(id))
        ? 'Some value is not valid'
        : null;
    }
  },
  watch: {
    hasErrors: {
      handler: 'updateErrorState',
      immediate: true
    }
  },
  methods: {
    textOverflowMiddle,
    updateErrorState(val: string): void {
      this.$emit('update:error', !!val);
    },
    handleListUpdate(): void {
      if (this.hasErrors) return;
      if (this.inputValue) return;

      this.$emit('update');
    },
    updateValue(val: string[]): void {
      this.$emit('input', val);
    },
    handleSpaceInput(event: KeyboardEvent): void {
      if (!this.preventSpaceHandle) return;

      this.$refs.userIdInput.onEnterDown(event);
    },
    handleListInput(val: string): void {
      if (!val) return;

      const list = this.usersProcessIds(val);

      if (list.length > 1) {
        this.updateValue([...new Set([...this.userList, ...list])]);

        this.$nextTick(() => {
          this.inputValue = null;
        });
      }
    },
    usersProcessIds(value: string): string[] {
      if (!value) return [];
      return value.split(/[\s\n;,]+/g).filter((v) => v);
    },
    validateUser(id: string): boolean {
      const minUserIdSymbolsCount: number = this.minSymbolsCount;

      return id.trim().length >= minUserIdSymbolsCount;
    },
    removeInputUser(val: string): void {
      this.updateValue(this.userList.filter((id): boolean => id !== val));
    }
  }
};
