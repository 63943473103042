





















import ConfirmationPromiseDialog from '@/components/ConfirmationPromiseDialog.vue';
import StatsCard from '@/components/StatsCard.vue';
import formatDate from '@/helpers/formatDate';
import { mapGetters } from 'vuex';
import { AxiosError } from 'axios';
import { getMaxGGRData, resetMaxGGR } from '@/api/MaxGGR';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { IMaxGgrResponse } from '@/api/schema';

export default {
  name: 'SettingsResetMaxGgrView',
  components: {
    ConfirmationPromiseDialog,
    StatsCard
  },
  data(): any {
    return {
      maxGgrValue: null,
      refreshDate: null,
      loading: {
        ggrData: false,
        reset: false,
        confirm: false
      }
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    formattedMaxGGR(): string {
      return `${this.$options.filters.numeralSpaces(
        this.maxGgrValue || 0,
        '0,0.[00]'
      )} USD`;
    },
    getMaxGgrDescription(): string {
      const label = 'Current MaxGGR';

      return this.refreshDate
        ? `${label} (Last reset: ${this.getFormatDate(this.refreshDate)})`
        : label;
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getMaxGgrData();
        }
      },
      immediate: true
    }
  },
  methods: {
    formatDate,
    getFormatDate(date: string): string {
      return formatDate(
        date.slice(0, date.lastIndexOf('.')),
        'yy.MM.dd HH:mm',
        false,
        true
      );
    },
    async getMaxGgrData(): Promise<any> {
      this.loading.ggrData = true;

      getMaxGGRData()
        .then((data: IMaxGgrResponse) => {
          this.maxGgrValue = data.maxGGR;
          this.refreshDate = data.refreshDate;
        })
        .catch((err: AxiosError) => {
          this.maxGgrValue = null;
          this.refreshDate = null;
          errorToastMessage(err);
        })
        .finally((): void => {
          this.loading.ggrData = false;
        });
    },
    async submitHandler(): Promise<void> {
      this.loading.confirm = true;

      const confirmData = await this.$refs.confirm.openDialog({
        title: `Reset MaxGGR`,
        text: `Are you sure you want to reset the maximum GGR?`,
        controls: {
          submit: {
            color: 'primary',
            text: 'Confirm'
          }
        }
      });

      this.loading.confirm = false;
      if (!confirmData) return;

      this.loading.reset = true;
      resetMaxGGR()
        .then((newMaxGgrValue: number) => {
          this.maxGgrValue = newMaxGgrValue;

          this.$toast.success('Reset Max GGR is successful');
        })
        .catch((err: AxiosError) => {
          errorToastMessage(err);
        })
        .finally((): void => {
          this.getMaxGgrData();
          this.loading.reset = false;
        });
    }
  }
};
