var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-combobox',{ref:"userIdInput",staticClass:"user-id-input",attrs:{"search-input":_vm.inputValue,"label":_vm.label,"append-icon":"","multiple":"","small-chips":"","deletable-chips":"","error-messages":_vm.hasErrors,"counter":_vm.userList.length > 0,"hint":_vm.hint,"outlined":"","clearable":""},on:{"update:searchInput":function($event){_vm.inputValue=$event},"update:search-input":[function($event){_vm.inputValue=$event},_vm.handleListInput],"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.handleListUpdate.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"space",32,$event.key,[" ","Spacebar"])){ return null; }return _vm.handleSpaceInput($event)}],"click:clear":function($event){return _vm.$emit('clear')}},scopedSlots:_vm._u([{key:"counter",fn:function(ref){
var props = ref.props;
return [(props.value)?_c('div',{staticClass:"text-gray text-caption"},[_vm._v(_vm._s(("Total: " + (props.value) + " users")))]):_vm._e()]}},{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({staticClass:"chip-item mt-2",attrs:{"color":_vm.validateUser(item) ? 'primary' : 'error',"input-value":selected,"close":"","outlined":"","label":""},on:{"click:close":function($event){return _vm.removeInputUser(item)}}},'v-chip',attrs,false),[_c('span',{staticClass:"pr-2"},[_vm._v(_vm._s(_vm.textOverflowMiddle(item, 6, 6)))])])]}}]),model:{value:(_vm.userList),callback:function ($$v) {_vm.userList=$$v},expression:"userList"}})}
var staticRenderFns = []

export { render, staticRenderFns }