




























































import { mapActions, mapState, mapGetters } from 'vuex';
import SettingsRolesDialog from '@/views/SettingsViews/SettingsRolesDialog.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import SettingsRolesSelect from '@/views/SettingsViews/SettingsRolesSelect.vue';
import type { AccountUser, ProgramDialogData } from '@/api/schema';
import { ERole } from '@/api/schema';

export default {
  name: 'SettingsRolesView',
  components: {
    ConfirmationDialog,
    SettingsRolesDialog,
    SettingsRolesSelect
  },
  data(): any {
    return {
      confirmationDialog: false,
      formDialog: false,
      dialogData: null,
      dialogOperation: null,
      dialogItem: null
    };
  },
  computed: {
    ...mapState('Operator', ['loading', 'users']),
    ...mapState('SuperAdmin', ['operator']),
    ...mapState('app', ['isSuperAdmin']),
    ...mapGetters('Onboarding', ['operatorId', 'isMobile']),
    canEdit(): boolean {
      return this.$role.can.update('settings roles');
    },
    tableHeaders(): any[] {
      return [
        { text: '#', value: 'id' },
        {
          text: this.isMobile ? 'Email' : 'Login',
          value: 'operatorUserId'
        },
        { text: 'Role', value: 'role' },
        ...[
          this.canEdit
            ? {
                text: 'Action',
                value: 'actions',
                sortable: false,
                align: 'right'
              }
            : null
        ]
      ].filter(Boolean);
    },
    isSuperOrAccount(): boolean {
      return (
        this.$role.is([
          'account',
          'admin',
          'copystake-account',
          'copystake-admin'
        ]) || this.isSuperAdmin
      );
    }
  },
  watch: {
    operator: {
      handler(): void {
        this.getUsers();
      },
      deep: true
    },
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getUsers();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Operator', [
      'getAccountUsers',
      'createAccountUser',
      'updateAccountUser',
      'deleteAccountUser'
    ]),
    getUsers(): void {
      this.getAccountUsers(this.$route.params.id);
    },
    openDialog(item: AccountUser, operation: string): void {
      this.dialogOperation = operation;
      this.dialogData = this.getDialogData(this.dialogOperation);
      this.dialogItem = item;
      const dialogType = this.getDialogTypeByOperation(operation);
      this[dialogType] = true;
    },
    closeDialog(): void {
      this.dialogOperation = null;
      this.dialogData = null;
      this.dialogItem = {};
      this.confirmationDialog = false;
      this.formDialog = false;
    },
    getDialogTypeByOperation(operation: string): string {
      return operation === 'create' || operation === 'update'
        ? 'formDialog'
        : 'confirmationDialog';
    },
    getDialogData(operation: string): ProgramDialogData {
      switch (operation) {
        case 'create':
          return {
            handler: this.createAccountUser,
            header: 'New user',
            buttonText: 'Create'
          };
        case 'update':
          return {
            handler: this.updateAccountUser,
            header: 'Edit user',
            buttonText: 'Update'
          };
        case 'delete':
          return {
            handler: this.deleteAccountUser,
            header: 'Are you sure you want to delete this user?'
          };
      }
    },
    confirmationDialogHandler(): void {
      const { handler } = this.dialogData;
      handler({
        userId: this.dialogItem.id,
        accountId: this.$route.params.id
      }).then((): void => {
        this.closeDialog();
      });
    },
    canEditAccountItem(role: ERole): boolean {
      if (this.isSuperOrAccount) return true;

      const arrDisabledEditRoles = [
        ERole.ROLE_OPERATOR_ADMIN,
        ERole.ROLE_ACCOUNT,
        ERole.ROLE_COPY_STAKE_ACCOUNT,
        ERole.ROLE_OPERATOR_COPY_STAKE_ADMIN
      ];

      return !arrDisabledEditRoles.includes(role);
    },
    formDialogHandler(data: AccountUser): void {
      const { handler } = this.dialogData;
      const dataToSend =
        this.dialogOperation === 'create'
          ? data
          : {
              id: data.id,
              userId: data.id,
              role: data.role,
              operators: data.operators,
              accountId: +this.$route.params.id
            };

      handler(dataToSend).then((): void => {
        this.closeDialog();
      });
    },
    changeRoleHandler(role: string, user: AccountUser): void {
      this.updateAccountUser({
        ...user,
        userId: user.id,
        accountId: +this.$route.params.id,
        role
      }).then(() => {
        this.$toast.success('Role changed.');
      });
    }
  }
};
