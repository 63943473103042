






























































import { capitalization } from '@/helpers/formatString';
import WidgetSocialMediaItem from '@/components/widget-settings/WidgetSocialMediaItem.vue';
import WidgetSocialItemDialog from '@/components/widget-settings/WidgetSocialItemDialog.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import {
  EWidgetSocialMedia,
  TWidgetSocialMediaList,
  ConfirmationDialogData,
  MediaItemDialogData
} from '@/api/schema';
import { WidgetSocialMediaService } from '@/api/WidgetSettings';
import { errorToastMessage } from '@/helpers/errorToastMessage';

const socialMediaIcons = Object.keys(EWidgetSocialMedia).reduce(
  (all, curr) => ({
    ...all,
    [curr]: () =>
      import(`@/assets/images/icons/social/${curr.toLowerCase()}.svg?inline`)
  }),
  {}
);

const socialMediaDefaultData = (): TWidgetSocialMediaList =>
  Object.keys(EWidgetSocialMedia).reduce(
    (all, curr: EWidgetSocialMedia) => ({
      ...all,
      [curr]: []
    }),
    {} as TWidgetSocialMediaList
  );

interface MediaViewData {
  socialMedia: TWidgetSocialMediaList;
  deleteModal: boolean;
  mediaItemModal: boolean;
  loading: boolean;
  processing: boolean;
  operationType: 'create' | 'delete' | 'update' | null;
  selectMediaItem: EWidgetSocialMedia;
}

export default {
  name: 'WidgetSocialMedia',
  components: {
    ...socialMediaIcons,
    WidgetSocialMediaItem,
    ConfirmationDialog,
    WidgetSocialItemDialog
  },
  data(): MediaViewData {
    return {
      deleteModal: false,
      mediaItemModal: false,
      loading: false,
      processing: false,
      operationType: null,
      selectMediaItem: {} as EWidgetSocialMedia,
      socialMedia: socialMediaDefaultData()
    };
  },
  computed: {
    deleteDialogData(): ConfirmationDialogData {
      return {
        header: 'Are you sure to delete this social media link ?',
        okText: 'Delete'
      };
    },
    mediaItemDialogData(): MediaItemDialogData {
      return {
        itemData: this.selectMediaItem,
        header: this.selectMediaItem.socialMediaType,
        okText: this.operationType === 'create' ? 'Save' : 'Update'
      };
    }
  },
  created(): void {
    this.getList();
  },
  methods: {
    capitalization,
    showItemModal(
      status: boolean,
      type: string,
      mediaItem: EWidgetSocialMedia = {} as EWidgetSocialMedia
    ): void {
      this.selectMediaItem = status ? mediaItem : {};
      this.operationType = type;
      this.$nextTick((): void => {
        if (type === 'delete') {
          this.deleteModal = status;
          return;
        }
        this.mediaItemModal = status;
      });
    },
    closeModals(): void {
      if (this.processing) return;
      this.deleteModal = false;
      this.mediaItemModal = false;
      this.$nextTick((): void => {
        this.selectMediaItem = {};
        this.operationType = null;
      });
    },
    async getList(): Promise<void> {
      this.loading = true;
      try {
        const list = await WidgetSocialMediaService.list().finally(
          (): void => {
            this.loading = false;
          }
        );
        this.socialMedia = { ...socialMediaDefaultData(), ...list };
      } catch (e) {
        console.log(e);
      }
    },
    handleAction(data: EWidgetSocialMedia): void {
      this.processing = true;
      if (!this.operationType) return;
      WidgetSocialMediaService[this.operationType](
        data || this.selectMediaItem
      )
        .then(this.getList)
        .then(() => {
          this.$toast.success(
            `Social media link has been ${this.operationType}d`
          );
        })
        .catch(errorToastMessage)
        .finally((): void => {
          this.processing = false;
          this.$nextTick((): void => {
            this.closeModals();
          });
        });
    }
  }
};
