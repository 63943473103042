











import { PropType } from 'vue';

type TTabsItem = {
  id: string;
  [key: string]: any;
};

export default {
  name: 'VTabsHash',

  props: {
    value: {
      type: Number,
      default: 0
    },
    tabs: {
      type: Array as PropType<TTabsItem[]>,
      required: true
    }
  },

  computed: {
    currentTab(): number {
      const { hash } = this.$route;

      if (!this.tabs.length || !hash) return 0;

      const findIndex = this.tabs.findIndex(
        (tab: TTabsItem): any => '#' + tab.id === hash
      );

      if (findIndex <= 0) return 0;

      return findIndex;
    }
  },

  methods: {
    changeTab(tab: number): void {
      this.$emit('input', tab);

      this.setHashUrl(tab);
    },

    setHashUrl(tab: number): void {
      if (!this.tabs.length) return;

      if (tab !== this.currentTab) {
        const id = this.tabs[tab].id;

        if (id) {
          this.$router.push({
            hash: id
          });
        }
      }
    },

    setCurrentTabByHash(): void {
      if (this.currentTab) {
        this.$emit('input', this.currentTab);
      }
    }
  },

  created(): void {
    this.setCurrentTabByHash();
  }
};
