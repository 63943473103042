import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import { AxiosResponse } from 'axios';
import { IMaxGgrResponse } from '@/api/schema';

/**
 * Get max GGR value.
 */
export async function getMaxGGRData(): Promise<IMaxGgrResponse> {
  return http
    .get(buildUrl(`/api/v1/operator/maxggr?operatorId={operatorId}`))
    .then(({ data }: AxiosResponse): IMaxGgrResponse => data);
}

/**
 * Reset max GGR.
 */
export async function resetMaxGGR(): Promise<number> {
  return http
    .get(buildUrl(`/api/v1/operator/maxggr-refresh?operatorId={operatorId}`))
    .then(({ data }: AxiosResponse): number => data);
}
