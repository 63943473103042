







































import { PropType } from 'vue';
import { IWidgetSocialMedia } from '@/api/schema';

export default {
  name: 'WidgetSocialMediaItem',
  props: {
    value: {
      type: Object as PropType<IWidgetSocialMedia>,
      required: true
    }
  }
};
