





























































import type { PropType } from 'vue';
import { textOverflowMiddle } from '@/helpers/formatString';
import UserIdInput from '@/components/UserIdInput.vue';
import uniq from 'lodash/uniq';

export default {
  name: 'IdsListManage',
  props: {
    presetIdsList: {
      type: Array as PropType<string[]>,
      default: (): string[] => []
    },
    disclaimer: {
      type: String,
      default: 'Operator User ID'
    },
    minSymbolsValidRule: {
      type: Number,
      default: 3
    },
    searchTitleDisclaimer: {
      type: String,
      default: ''
    }
  },
  components: { UserIdInput },
  data: (): any => ({
    idsInput: [],
    idsList: [],
    searchPhrase: '',
    hasErrors: false
  }),
  computed: {
    headerSearchBlock(): string {
      return `
          <p>
            Look up users who have been added to the <strong>${this.searchTitleDisclaimer}</strong>.
          </p>`;
    },
    filteredIds(): string[] {
      if (!this.searchPhrase) return this.idsList;

      return this.idsList.filter((userId: string) =>
        userId.includes(this.searchPhrase)
      );
    }
  },
  watch: {
    presetIdsList: {
      handler(): void {
        this.idsList = [...this.presetIdsList];
      },
      immediate: true
    }
  },
  methods: {
    textOverflowMiddle,
    updateIdsList(): void {
      if (!this.hasErrors) {
        this.idsList = uniq([...this.idsList, ...this.idsInput]);

        this.$emit('updateIdsList', this.idsList);
        this.clearIdsInput();
      }
    },
    removeId(userId: string): void {
      this.idsList = this.idsList.filter(
        (item: string): boolean => item !== userId
      );

      this.$emit('updateIdsList', this.idsList);
    },
    clearIdsInput(): void {
      this.idsInput = [];
    },
    clearIdsList(): void {
      this.idsList = [];

      this.$emit('updateIdsList', this.idsList);
    }
  }
};
