





















































import { mapActions, mapGetters, mapState } from 'vuex';
import {
  adminGetOperatorFeatureFlags,
  getOperatorFeatureFlags
} from '@/api/FeatureFlags';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import FeatureCard from '@/components/feature-flags/FeatureFlagCard.vue';
import FeatureFlagManageDialog from '@/components/feature-flags/FeatureFlagManageDialog.vue';
import type { AxiosError } from 'axios';
import type { FeatureFlagItem } from '@/api/schema';
import { EFeatureFlagType } from '@/api/schema';
import { formatFeatureName } from '@/helpers/featureFlagHelpers';

export default {
  name: 'FeatureFlagsView',
  components: {
    FeatureCard,
    FeatureFlagManageDialog
  },
  filters: {
    formatFeatureName
  },
  data(): any {
    return {
      featureManageModalState: false,
      manageModalFeature: {} as FeatureFlagItem,

      features: [] as FeatureFlagItem[],
      loading: {
        featuresList: false,
        updateManageModal: false
      },
      request: null,
      selectedCategoriesType: EFeatureFlagType.PRODUCT,
      selectCategoriesTypes: [
        {
          text: 'Product Features',
          value: EFeatureFlagType.PRODUCT
        },
        {
          text: 'Tech Features',
          value: EFeatureFlagType.TECH
        }
      ]
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('app', ['isSuperAdmin']),
    sortedFeatureList(): FeatureFlagItem[] {
      const sortedFeatures = [...this.features].sort((a, b) =>
        (a.name === 'live_streamer' ? 'copystake' : a.name).localeCompare(
          b.name === 'live_streamer' ? 'copystake' : b.name
        )
      );

      return this.isSuperAdmin
        ? sortedFeatures
        : sortedFeatures.filter((feature: FeatureFlagItem) => {
            return (
              feature.adminVisible &&
              feature.type === EFeatureFlagType.PRODUCT
            );
          });
    },
    isLoading(): boolean {
      return this.loading.featuresList && !this.request;
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (!newId) return;

        this.getFeatureFlagsData();
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('FeatureFlags', ['getOperatorFeatureFlags']),
    async getFeatureFlagsData(): Promise<void> {
      this.loading.featuresList = true;
      this.features = [];

      const request = this.isSuperAdmin
        ? adminGetOperatorFeatureFlags
        : getOperatorFeatureFlags;

      await request(this.selectedCategoriesType)
        .then((data: FeatureFlagItem[]): void => {
          this.features = data;
        })
        .catch((error: AxiosError): void => {
          errorToastMessage(error);
        })
        .finally((): void => {
          this.loading.featuresList = false;
        });
    },
    updateDataWithTimeout(): void {
      clearTimeout(this.request);

      this.$nextTick(() => {
        this.request = setTimeout(this.getOperatorFeatureFlags, 1000);
      });
    },
    handlerCategoriesTypeChange(): void {
      this.getFeatureFlagsData();
    },
    featureManageModalStateHandler(feature: FeatureFlagItem): void {
      if (feature) {
        this.manageModalFeature = feature;
        this.featureManageModalState = true;
      }
    },
    submitManageModalHandler(): void {
      this.loading.updateManageModal = true;

      this.getFeatureFlagsData().then((): void => {
        this.manageModalFeature = this.features.find(
          (feature) =>
            feature.featureFlagId === this.manageModalFeature.featureFlagId
        );

        this.loading.updateManageModal = false;
      });
    }
  }
};
