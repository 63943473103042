









































import { mapState } from 'vuex';
import * as vr from '@/helpers/validation';
import type { SelectItem, TCopyStakePackageResponse } from '@/api/schema';
import { ECopyStakePackageProgram } from '@/api/schema';
import { getCopyStakePackage, updateCopyStakePackage } from '@/api/CopyStake';

type SettingsCopyStakePackageViewData = {
  copyStakePackage: TCopyStakePackageResponse | null;
  loading: boolean;
  formData: {
    program: ECopyStakePackageProgram;
    streamersLimit: string;
    watchersLimit: string;
  };
  areLimitsFieldsEditable: boolean;
  isFormValid: boolean;
};

export default {
  name: 'SettingsCopyStakePackageView',
  data(): SettingsCopyStakePackageViewData {
    return {
      loading: true,
      copyStakePackage: null,
      formData: {
        program: ECopyStakePackageProgram.MINI_PROVISION,
        streamersLimit: '0',
        watchersLimit: '0'
      },
      areLimitsFieldsEditable: true,
      isFormValid: false
    };
  },
  computed: {
    ...mapState('SuperAdmin', ['operator']),
    isFormDisabled(): boolean {
      return this.loading;
    },
    rules(): Record<string, unknown[]> {
      return {
        streamersLimit: [
          vr.required,
          vr.validNumber,
          vr.integer,
          vr.positiveNumber
        ],
        watchersLimit: [
          vr.required,
          vr.validNumber,
          vr.integer,
          vr.positiveNumber
        ]
      };
    },
    programs(): SelectItem[] {
      return [
        {
          text: 'Mini Provision',
          value: ECopyStakePackageProgram.MINI_PROVISION
        },
        {
          text: 'Standard Provision',
          value: ECopyStakePackageProgram.STANDARD_PROVISION
        },
        {
          text: 'Enterprise',
          value: ECopyStakePackageProgram.ENTERPRISE
        }
      ];
    },
    programsSettingMap(): Record<
      ECopyStakePackageProgram,
      {
        streamersLimit: number | null;
        watchersLimit: number | null;
        areLimitsFieldsEditable: boolean;
      }
    > {
      return {
        [ECopyStakePackageProgram.MINI_PROVISION]: {
          streamersLimit: 2,
          watchersLimit: 500,
          areLimitsFieldsEditable: false
        },
        [ECopyStakePackageProgram.STANDARD_PROVISION]: {
          streamersLimit: 5,
          watchersLimit: 2000,
          areLimitsFieldsEditable: false
        },
        [ECopyStakePackageProgram.ENTERPRISE]: {
          streamersLimit: null,
          watchersLimit: null,
          areLimitsFieldsEditable: true
        }
      };
    }
  },
  created(): void {
    this.loadCopyStakePackage();
  },
  watch: {
    operator: {
      handler(): void {
        this.loadCopyStakePackage();
      },
      deep: true
    },
    copyStakePackage: {
      handler(): void {
        if (this.copyStakePackage === null) {
          return;
        }

        if (
          this.copyStakePackage.streamersLimit === null ||
          this.copyStakePackage.watchersLimit === null
        ) {
          this.formData.program = ECopyStakePackageProgram.MINI_PROVISION;
          this.formData.streamersLimit = 2;
          this.formData.watchersLimit = 500;

          return;
        }

        this.formData.streamersLimit = `${
          this.copyStakePackage.streamersLimit ?? 0
        }`;
        this.formData.watchersLimit = `${
          this.copyStakePackage.watchersLimit ?? 0
        }`;
        this.formData.program = ECopyStakePackageProgram.ENTERPRISE;

        for (const program in this.programsSettingMap) {
          const { streamersLimit, watchersLimit } =
            this.programsSettingMap[program];

          if (
            this.copyStakePackage.streamersLimit === streamersLimit &&
            this.copyStakePackage.watchersLimit === watchersLimit
          ) {
            this.formData.program = program;

            break;
          }
        }

        this.$refs.form?.resetValidation();
      },
      deep: true
    },
    'formData.program': {
      handler(program: ECopyStakePackageProgram): void {
        const { streamersLimit, watchersLimit, areLimitsFieldsEditable } =
          this.programsSettingMap[program];

        this.formData.streamersLimit = `${
          streamersLimit ?? this.copyStakePackage.streamersLimit ?? 0
        }`;
        this.formData.watchersLimit = `${
          watchersLimit ?? this.copyStakePackage.watchersLimit ?? 0
        }`;
        this.areLimitsFieldsEditable = areLimitsFieldsEditable;

        this.$refs.form?.resetValidation();
      },
      deep: true
    }
  },
  methods: {
    resetComponentState(): void {
      this.loading = true;
      this.copyStakePackage = null;
      this.formData = {
        program: ECopyStakePackageProgram.MINI_PROVISION,
        streamersLimit: '2',
        watchersLimit: '500'
      };
      this.areLimitsFieldsEditable = true;
      this.isFormValid = false;
      this.$refs.form?.resetValidation();
    },
    async loadCopyStakePackage(): Promise<void> {
      try {
        this.resetComponentState();
        this.copyStakePackage = await getCopyStakePackage();
        this.loading = false;
      } catch (error) {
        this.$toast.error('Something was wrong', error);
      }
    },
    async submitHandler(): Promise<void> {
      if (this.isFormDisabled) {
        return;
      }

      const form = this.$refs.form;
      form.validate();

      await this.$nextTick();

      if (!this.isFormValid) {
        return;
      }

      try {
        const copyStakePackage = await updateCopyStakePackage({
          streamersLimit: +this.formData.streamersLimit,
          watchersLimit: +this.formData.watchersLimit
        });
        this.resetComponentState();
        this.copyStakePackage = { ...copyStakePackage };
        this.loading = false;

        this.$toast.success('CopyStake package successfully updated!');
      } catch (error) {
        this.$toast.error('Something was wrong', error);
      }
    }
  }
};
