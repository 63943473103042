import { http } from './Connect';
import { buildUrl } from '@/helpers/buildUrl';
import type { AxiosResponse } from 'axios';
import { IWidgetTheme, IWidgetSocialMedia } from '@/api/schema';

/**
 * Get current widget theme
 */
export async function getWidgetTheme(): Promise<IWidgetTheme> {
  return http
    .get(buildUrl('/api/v1/operator/{operatorId}/widget-theme'))
    .then(({ data }: AxiosResponse): IWidgetTheme => data);
}

/**
 * Set current widget theme
 */
export async function setWidgetTheme(theme: IWidgetTheme): Promise<unknown> {
  return http.put(
    buildUrl('/api/v1/operator/{operatorId}/widget-theme'),
    theme
  );
}

/**
 * CRUD for operator social media
 */
export class WidgetSocialMediaService {
  static create(data: IWidgetSocialMedia): Promise<any> {
    return http.post(
      buildUrl('/api/v1/operator/{operatorId}/social-media'),
      data
    );
  }

  static update(data: IWidgetSocialMedia): Promise<unknown> {
    return http.patch(
      buildUrl(`/api/v1/operator/{operatorId}/social-media/${data.id}`),
      {
        link: data.link,
        title: data.title
      }
    );
  }

  static delete(data: IWidgetSocialMedia): Promise<unknown> {
    return http.delete(
      buildUrl(`/api/v1/operator/{operatorId}/social-media/${data.id}`)
    );
  }

  static list(): Promise<IWidgetSocialMedia[]> {
    return http
      .get(buildUrl('/api/v1/operator/{operatorId}/social-media'))
      .then(({ data }: AxiosResponse): IWidgetSocialMedia[] => {
        return data.reduce((all, curr) => {
          const isExist = Array.isArray(all[curr.socialMediaType]);
          const current = isExist ? all[curr.socialMediaType] : [];
          all[curr.socialMediaType] = [...current, curr];

          return all;
        }, {});
      });
  }
}
