
































import { mapGetters, mapActions, mapState } from 'vuex';
import type { ClientsInfoState } from '@/store/modules/clients-info/ClientsInfoState';

export default {
  name: 'CurrencySelectView',
  data(): any {
    return {
      selectCurrency: null,
      items: ['USD', 'EUR', 'RON', 'TRY', 'CAD', 'CLP']
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('ClientsInfo', {
      currency: (state: ClientsInfoState) =>
        state.clientsInfo.adminCurrencyTicker
    }),
    ...mapState('Operator', ['loading']),
    disabled(): boolean {
      return (
        this.currency === this.selectCurrency ||
        this.$role.is(['super-admin-finance', 'super-admin-manager'])
      );
    },
    disableSelect(): boolean {
      return this.$role.is(['super-admin-finance', 'super-admin-manager']);
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.selectCurrency = this.currency;
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Operator', ['updateOperatorCurrency']),
    ...mapActions('TokensInfo', ['getTokenPriceData']),
    changCurrency(): void {
      this.updateOperatorCurrency(this.selectCurrency).then((): void => {
        this.getTokenPriceData();
        this.$toast.success('Currency has been updated in widget settings');
      });
    }
  }
};
