












































import type { PropType } from 'vue';
import { ConfirmationDialogData } from '@/api/schema';

export default {
  name: 'ConfirmationDialog',
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    data: {
      type: Object as PropType<ConfirmationDialogData>,
      default: (): ConfirmationDialogData => {
        return {} as ConfirmationDialogData;
      }
    },
    processing: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    closable: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    buttonType(): string {
      return this.data?.isPrimary ? 'primary' : 'error';
    },
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    header(): string {
      return this.data?.header || '';
    },
    disclaimer(): string {
      return this.data?.disclaimer || '';
    },
    icon(): string {
      return this.data?.icon;
    },
    okText(): string {
      return this.data?.okText || 'OK';
    },
    cancelText(): string {
      return this.data?.cancelText || 'Cancel';
    },
    maxWidth(): string {
      return `${this.data?.maxWidth || 550}px`;
    }
  },
  methods: {
    handlerOnSubmit(): void {
      if (typeof this.data?.handler === 'function') {
        this.data.handler();
      }

      this.$emit('submit');
    }
  }
};
