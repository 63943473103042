

















































































import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';
import { getLangLabel, LangsArray } from '@/helpers/langHelpers';

export default {
  name: 'SettingsLanguagesView',
  data(): any {
    return {
      availableLanguages: [...LangsArray],
      selectedLanguages: [],
      timeoutID: null
    };
  },
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Operator', ['loading', 'defaultLanguage', 'languages']),
    widgetLanguages(): { label: string; value: string }[] {
      return LangsArray.filter((lang) => this.languages.includes(lang.value));
    }
  },
  watch: {
    operatorId: {
      handler: async function (newId: number | null): Promise<void> {
        if (newId) {
          await this.getOperatorLanguages();
          this.selectedLanguages = [...this.languages];
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Operator', [
      'getOperatorLanguages',
      'updateOperatorDefaultLanguage',
      'updateOperatorWidgetLanguagesList'
    ]),
    ...mapMutations('Operator', ['setDefaultLanguage']),
    onLanguagesChange(languages: string[]): void {
      if (!languages.length) return;

      if (this.timeoutID) {
        clearTimeout(this.timeoutID);
      }

      this.timeoutID = setTimeout(() => {
        // Set order like in select menu
        this.selectedLanguages = LangsArray.reduce((acc, item) => {
          if (languages.includes(item.value)) {
            acc.push(item.value);
          }
          return acc;
        }, []);

        if (!this.selectedLanguages.includes(this.defaultLanguage)) {
          this.onDefaultLanguageChange(this.selectedLanguages[0]);
        }

        this.updateOperatorWidgetLanguagesList(this.selectedLanguages);
      }, 1000);
    },
    onDefaultLanguageChange(language: string): void {
      if (!language) return;
      this.updateOperatorDefaultLanguage(language).then((): void => {
        const langLabel = getLangLabel(language);

        this.$toast.success(`Language ${langLabel} has been set for widget`);
      });
    }
  }
};
