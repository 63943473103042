























import { mapActions, mapGetters, mapState } from 'vuex';
import { IOperatorEventsParams, TOperatorEventsType } from '@/api/schema';
import { toTitleCase } from '@/helpers/formatString';

interface IEventItem {
  name: string;
  type: TOperatorEventsType;
  value: boolean;
}

export default {
  name: 'SettingsEventsView',
  computed: {
    ...mapGetters('Onboarding', ['operatorId']),
    ...mapState('Operator', ['loading', 'operatorEvents']),
    isLoading(): boolean {
      return this.loading.operatorEvents;
    },
    events(): Array<IEventItem> {
      return Object.keys(this.operatorEvents).map((key) => ({
        name: key.replace(/_/g, ' '),
        type: key as TOperatorEventsType,
        value: this.operatorEvents[key]
      }));
    }
  },
  watch: {
    operatorId: {
      handler(newId: number | null): void {
        if (newId) {
          this.getOperatorEvents();
        }
      },
      immediate: true
    }
  },
  methods: {
    ...mapActions('Operator', ['getOperatorEvents', 'updateOperatorEvents']),
    toTitleCase,
    onCheckboxChange(event: IEventItem, status: boolean): void {
      const params: IOperatorEventsParams = {
        type: event.type,
        active: status
      };

      this.updateOperatorEvents(params).then(() => {
        this.$toast.success(
          `${event.name} ${
            status ? 'event has been turned on' : 'event has been turned off'
          }`
        );
      });
    }
  }
};
