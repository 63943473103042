

























































import { capitalization } from '@/helpers/formatString';
import { mediaUrl, maxLength, required } from '@/helpers/validation';
import {
  MediaItemDialogData,
  IWidgetSocialMedia,
  EWidgetSocialMedia
} from '@/api/schema';
import type { PropType } from 'vue';

const socialMediaIcons = Object.keys(EWidgetSocialMedia).reduce(
  (all, curr) => ({
    ...all,
    [curr]: () =>
      import(`@/assets/images/icons/social/${curr.toLowerCase()}.svg?inline`)
  }),
  {}
);

export default {
  name: 'WidgetSocialItemDialog',
  data(): Partial<IWidgetSocialMedia> & {
    rules: unknown;
    formIsValid: boolean;
    itemData: IWidgetSocialMedia;
  } {
    return {
      formIsValid: true,
      itemData: {} as IWidgetSocialMedia,
      rules: {
        title: [required, (v) => maxLength(v, 20)],
        link: [required, mediaUrl]
      }
    };
  },
  components: {
    ...socialMediaIcons
  },
  props: {
    value: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    },
    dialogData: {
      type: Object as PropType<MediaItemDialogData>,
      default: (): MediaItemDialogData => ({} as MediaItemDialogData)
    },
    processing: {
      type: Boolean as PropType<boolean>,
      default: (): boolean => false
    }
  },
  computed: {
    show: {
      get(): boolean {
        return this.value;
      },
      set(value: boolean): void {
        this.$emit('input', value);
      }
    },
    disableSubmit(): boolean {
      return !this.formIsValid || !this.itemData.title || !this.itemData.link;
    }
  },
  watch: {
    dialogData(newValue: MediaItemDialogData): void {
      if (newValue) {
        this.$refs?.form?.reset?.();
        this.itemData = { ...newValue.itemData };
      }
    }
  },
  methods: {
    capitalization,
    submitHandler(): void {
      const form = this.$refs.form;
      form.validate();
      this.$nextTick((): void => {
        if (this.formIsValid) {
          this.$emit('submit', {
            ...this.itemData,
            link: this.itemData.link
          });
        }
      });
    }
  }
};
