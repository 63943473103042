export const LangsArray = [
  { label: 'English', value: 'en' },
  { label: 'French', value: 'fr' },
  { label: 'German', value: 'de' },
  { label: 'Japanese', value: 'ja' },
  { label: 'Polish', value: 'pl' },
  { label: 'Portuguese', value: 'pt' },
  { label: 'Spanish', value: 'es' },
  { label: 'Turkish', value: 'tr' },
  { label: 'Russian', value: 'ru' },
  { label: 'Indonesian', value: 'id' },
  { label: 'Creole', value: 'cr' },
  { label: 'Czech', value: 'cs' },
  { label: 'Hungarian', value: 'hu' },
  { label: 'Lithuanian', value: 'lt' },
  { label: 'Latvian', value: 'lv' },
  { label: 'Slovakian', value: 'sk' },
  { label: 'Bengali', value: 'bn' },
  { label: 'Kazakh', value: 'kz' },
  { label: 'Ukrainian', value: 'uk' },
  { label: 'Uzbek', value: 'uz' }
];

export const getLangLabel = (value: string): string | null => {
  if (!value) {
    return '-';
  }

  return LangsArray.find((v) => v.value === value)?.label || '-';
};
