








export default {
  name: 'VStatus',
  props: {
    type: {
      default: 'primary',
      type: String
    },
    largeText: {
      default: false,
      type: Boolean
    },
    outlined: {
      default: false,
      type: Boolean
    }
  }
};
