


























































import { PropType } from 'vue';
import { mapState } from 'vuex';
import VStatus from '@/components/VStatus.vue';
import Tooltip from '@/components/tooltip/Tooltip.vue';
import ConfirmationDialog from '@/components/ConfirmationDialog.vue';
import {
  adminEnableOperatorFeatureFlag,
  adminVisibleOperatorFeatureFlag,
  enableOperatorFeatureFlag
} from '@/api/FeatureFlags';
import { errorToastMessage } from '@/helpers/errorToastMessage';
import { formatFeatureName } from '@/helpers/featureFlagHelpers';
import {
  EFeatureFlagListType,
  EFeatureFlagType,
  FeatureFlagItem
} from '@/api/schema';

const tooltipTextMap = {
  // live_streamer: 'test tooltip', // (add tooltips value)
  // liquidity_staking_token_usdt: 'test tooltip' // (add tooltips value)
};

const enum EFeatureFlagUpdateTypes {
  ENABLE = 'ENABLE',
  ADMIN_VISIBLE = 'ADMIN_VISIBLE'
}

export default {
  name: 'FeatureFlagCard',
  components: {
    Tooltip,
    VStatus,
    ConfirmationDialog
  },
  filters: {
    formatFeatureName
  },
  props: {
    feature: {
      type: Object as PropType<FeatureFlagItem>,
      default: {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  data(): any {
    return {
      EFeatureFlagType,
      tooltipTextMap,
      confirmationDialogState: false,
      confirmationDialogData: null,
      isProcessingEnableSwitch: false,
      isProcessingVisibleSwitch: false,
      isLoading: false
    };
  },
  computed: {
    ...mapState('app', ['isSuperAdmin']),
    formattedFeatureName(): string {
      return this.$options.filters.formatFeatureName(this.feature.name);
    },
    getCountIcon(): string {
      switch (this.feature.listType) {
        case EFeatureFlagListType.WHITE_LIST:
          return 'mdi-account-multiple-check';
        case EFeatureFlagListType.BLACK_LIST:
          return 'mdi-account-multiple-remove';
        case EFeatureFlagListType.AFFILIATE_ALL_USERS:
          return 'mdi-account-multiple-outline';
        case EFeatureFlagListType.AFFILIATE_WHITE_LIST:
          return 'mdi-account-multiple-check-outline';
        case EFeatureFlagListType.AFFILIATE_BLACK_LIST:
          return 'mdi-account-multiple-remove-outline';
        default:
          return 'mdi-account-multiple';
      }
    },
    getCountText(): string {
      switch (this.feature.listType) {
        case EFeatureFlagListType.WHITE_LIST:
        case EFeatureFlagListType.BLACK_LIST:
          return this.feature.operatorUserIds.length;
        case EFeatureFlagListType.AFFILIATE_WHITE_LIST:
        case EFeatureFlagListType.AFFILIATE_BLACK_LIST:
          return this.feature.partnerIds.length;
        case EFeatureFlagListType.AFFILIATE_ALL_USERS:
          return 'All affiliate';
        default:
          return 'All users';
      }
    }
  },
  methods: {
    changeEnableStatusHandler(): void {
      this.isProcessingEnableSwitch = true;
      const newStatus = !this.feature.enabled;

      this.confirmationDialogData = {
        header: `Are you sure you want to ${
          newStatus ? 'activate' : 'deactivate'
        } "${this.formattedFeatureName}"?`,
        data: {
          status: newStatus,
          type: EFeatureFlagUpdateTypes.ENABLE
        },
        okText: newStatus ? 'Activate' : 'Deactivate'
      };

      this.confirmationDialogState = true;
    },
    changeVisibleStatusHandler(): void {
      this.isProcessingVisibleSwitch = true;
      const newStatus = !this.feature.adminVisible;

      this.confirmationDialogData = {
        header: `Are you sure you want to ${
          newStatus ? 'visible' : 'hide'
        } "${this.formattedFeatureName}" for operator?`,
        data: {
          status: newStatus,
          type: EFeatureFlagUpdateTypes.ADMIN_VISIBLE
        },
        okText: newStatus ? 'Ok' : 'Hide'
      };

      this.confirmationDialogState = true;
    },
    async updateFeature(): Promise<void> {
      this.isLoading = true;
      this.confirmationDialogState = false;

      const { status, type } = this.confirmationDialogData.data;

      try {
        if (type === EFeatureFlagUpdateTypes.ENABLE) {
          const request = this.isSuperAdmin
            ? adminEnableOperatorFeatureFlag
            : enableOperatorFeatureFlag;

          await request(this.feature.featureFlagId, status);
          this.feature.enabled = status;

          this.$toast.success(
            `${this.formattedFeatureName} ${
              status ? 'has been turned on' : 'has been turned off'
            }`
          );
        } else if (type === EFeatureFlagUpdateTypes.ADMIN_VISIBLE) {
          await adminVisibleOperatorFeatureFlag(
            this.feature.featureFlagId,
            status
          );
          this.feature.adminVisible = status;

          this.$toast.success(
            `${this.formattedFeatureName} ${
              status
                ? 'has been made visible to the operator'
                : 'has been hidden from the operator'
            }`
          );
        }
      } catch (error) {
        errorToastMessage(error);
        this.$emit('error');
      } finally {
        this.isLoading = false;
        this.closeConfirmationDialogHandler();
        this.$emit('updateData');
      }
    },
    closeConfirmationDialogHandler(): void {
      this.isProcessingEnableSwitch = false;
      this.isProcessingVisibleSwitch = false;
      this.confirmationDialogData = null;

      this.confirmationDialogState = false;
    },
    featureManageModalHandler(feature: FeatureFlagItem): void {
      this.$emit('showFeatureManageModal', feature);
    }
  }
};
