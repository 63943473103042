var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('div',{staticClass:"d-flex flex-column flex-sm-row"},[_c('v-card',{staticClass:"w-full mb-6 mb-sm-0 mr-sm-6",attrs:{"loading":_vm.loading.languages}},[_c('v-card-text',{staticClass:"pa-5"},[_c('v-avatar',{staticClass:"mb-4",attrs:{"color":"#E3F2FD","size":"32"}},[_c('v-icon',{staticClass:"main-text",attrs:{"size":"18"}},[_vm._v("mdi-format-list-bulleted")])],1),_c('h6',{staticClass:"text-subtitle-1 font-weight-semibold"},[_vm._v("List of Languages")]),_c('p',{staticClass:"secondary--text text-body-2"},[_vm._v("All languages you choose will be accessible in the widget's language list.")]),_c('v-form',[_c('v-select',{attrs:{"items":_vm.availableLanguages,"label":"Choose the language","item-text":"label","multiple":"","clearable":"","open-on-clear":"","single-line":"","chips":"","dense":"","outlined":"","validate-on-blur":"","hide-details":"auto","error-messages":!_vm.selectedLanguages.length ? 'At least one language should be selected.' : '',"menu-props":{offsetY: true},"disabled":_vm.loading.languages || _vm.$role.is(['super-admin-finance', 'super-admin-manager'])},on:{"change":_vm.onLanguagesChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(index <= 3)?_c('v-chip',{staticClass:"pl-1",attrs:{"small":""}},[_c('div',{staticClass:"d-flex flex-nowrap align-center"},[_c('div',{staticClass:"flag sm",class:'flag-' + item.value}),_c('span',{staticClass:"ml-1"},[_vm._v(" "+_vm._s(item.label))])])]):_vm._e(),(index === 4)?_c('v-chip',{attrs:{"small":""}},[_vm._v("+"+_vm._s(_vm.languages.length - 4))]):_vm._e()]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"height":"50px"}},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":attrs.inputValue}})],1),_c('div',{staticClass:"d-flex flex-nowrap ml-2"},[_c('div',{staticClass:"flag",class:'flag-' + item.value}),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(item.label))])])],1)]}}]),model:{value:(_vm.selectedLanguages),callback:function ($$v) {_vm.selectedLanguages=$$v},expression:"selectedLanguages"}})],1)],1)],1),_c('v-card',{staticClass:"w-full",attrs:{"loading":_vm.loading.defaultLanguage}},[_c('v-card-text',{staticClass:"pa-5"},[_c('v-avatar',{staticClass:"mb-4",attrs:{"color":"#E3F2FD","size":"32"}},[_c('v-icon',{staticClass:"main-text",attrs:{"size":"18"}},[_vm._v("mdi-web")])],1),_c('h6',{staticClass:"text-subtitle-1 font-weight-semibold"},[_vm._v("Default Language")]),_c('p',{staticClass:"secondary--text text-body-2"},[_vm._v("This language will be the default language displayed in the widget.")]),_c('v-select',{attrs:{"value":_vm.defaultLanguage,"items":_vm.widgetLanguages,"label":"Choose the language","item-text":"label","dense":"","single-line":"","outlined":"","hide-details":"","menu-props":{offsetY: true},"disabled":_vm.loading.languages || _vm.loading.defaultLanguage || _vm.$role.is(['super-admin-finance', 'super-admin-manager'])},on:{"change":_vm.onDefaultLanguageChange},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [_c('div',{staticClass:"d-flex flex-nowrap align-center"},[_c('div',{staticClass:"flag sm",class:'flag-' + item.value}),_c('span',{staticClass:"ml-2"},[_vm._v(" "+_vm._s(item.label))])])]}},{key:"item",fn:function(ref){
var item = ref.item;
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({staticStyle:{"height":"50px"}},'v-list-item',attrs,false),on),[_c('v-list-item-action',[_c('v-checkbox',{attrs:{"input-value":attrs.inputValue,"on-icon":"mdi-radiobox-marked","off-icon":"mdi-radiobox-blank"}})],1),_c('div',{staticClass:"d-flex flex-nowrap ml-2"},[_c('div',{staticClass:"flag",class:'flag-' + item.value}),_c('span',{staticClass:"ml-3"},[_vm._v(" "+_vm._s(item.label))])])],1)]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }